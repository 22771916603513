import React from "react";
import TextBanner from "../../components/banner/TextBanner";
import java from "../../assets/logos/java.jpg";
import node from "../../assets/logos/node.jpg";
import vuejs from "../../assets/logos/vuejs_min.jpg";
import react_logo from "../../assets/logos/react_logo.jpg";
import Python from "../../assets/logos/Python.jpg";
import PHP from "../../assets/logos/PHP.jpg";
import c_sharp from "../../assets/logos/c_sharp.jpg";
import C_Logo from "../../assets/logos/C++_Logo.jpg";
import laravel from "../../assets/logos/laravel.jpg";
import symfony from "../../assets/logos/symfony.jpg";
import Django from "../../assets/logos/Django.jpg";
import ExpressJS from "../../assets/logos/ExpressJS.jpg";
import spring from "../../assets/logos/spring.jpg";
import iOS from "../../assets/logos/iOS.jpg";
import react_native from "../../assets/logos/react_native.jpg";
import flutter from "../../assets/logos/flutter.jpg";
import Kotlin from "../../assets/logos/Kotlin.jpg";
import Ionic from "../../assets/logos/Ionic.jpg";
import MySQL from "../../assets/logos/MySQL.jpg";
import microsoft_sql from "../../assets/logos/microsoft_sql.jpg";
import postgresql from "../../assets/logos/postgresql.jpg";
import mariadb from "../../assets/logos/mariadb.jpg";
import mongodb from "../../assets/logos/mongodb.jpg";
import Redis from "../../assets/logos/Redis.jpg";
import Oracle from "../../assets/logos/Oracle.jpg";
import Go from "../../assets/logos/Go.jpg";
import angular from "../../assets/logos/angular.jpg";
import android from "../../assets/logos/android.jpg";
import RStudio from "../../assets/logos/RStudio.jpg";
import hadoop from "../../assets/logos/hadoop.jpg";
import PowerBI from "../../assets/logos/PowerBI.jpg";
import OpenCV from "../../assets/logos/OpenCV.jpg";

let logos = [
  react_logo,
  vuejs,
  node,
  java,
  Python,
  PHP,
  c_sharp,
  C_Logo,
  laravel,
  symfony,
  Django,
  ExpressJS,
  spring,
  iOS,
  react_native,
  flutter,
  Kotlin,
  Ionic,
  MySQL,
  microsoft_sql,
  postgresql,
  mariadb,
  mongodb,
  Redis,
  Oracle,
  Go,
  angular,
  android,
  RStudio,
  hadoop,
  PowerBI,
  OpenCV,
];

const Technologies: React.FC = () => {
  return (
    <div>
      <section className="w-full py-20 bg-[url('./assets/images/work-2.jpg')] bg-center bg-cover">
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center mb-4">
            <h2 className="font-semibold text-white text-5xl mb-5">
              Technologies
            </h2>
            <span className="font-normal text-base text-white">
              We specialize in helping you build a team of expert developers.
            </span>
          </div>
          <div className="flex justify-around w-full px-52"></div>
        </div>
      </section>
      <div className="mt-14 px-52">
        <TextBanner
          title="Technologies"
          description="Using the right technology for the right problem is our mantra. We are agile about learning new processes and tools to save time and reduce complexity"
        />
      </div>

      <div className="container items-center grid grid-cols-6 gap-2 px-52 my-8">
        {logos.map((i) => (
          <div key={i} className="rounded">
            <img className="max-h-32" src={i} alt={i} />
          </div>
        ))}

      </div>
      <br />
    </div>
  );
};

export default Technologies;
