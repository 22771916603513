import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { COMMON_CONSTANTS } from "../../constants/common.constants";

const { EN } = COMMON_CONSTANTS.LANGUAGES;

i18next.use(initReactI18next).init({
  fallbackLng: EN,
  lng: EN,
  resources: {
    [`${EN}`]: {
      glossary: require("./locales/en/glossary.json"),
    },
  },
  ns: ["glossary"],
  defaultNS: "common",
  debug: true,
});

i18next.languages = [EN];

export default i18next;
