import React from "react";
import TextBanner from "../../components/banner/TextBanner";
import TextSection from "../../components/textSection";
import link from "../../assets/icons/link.png";
import management from "../../assets/icons/management.png";
import outsourcing from "../../assets/icons/outsourcing.png";
import internet from "../../assets/icons/internet.png";

const OurServices: React.FC = () => {
  return (
    <div>
      <section className="w-full py-20 bg-[url('./assets/images/work-2.jpg')] bg-center bg-cover">
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center mb-4">
            <h2 className="font-semibold text-white text-5xl mb-5">
              Our Services
            </h2>
            <span className="font-normal text-base text-white">
              We specialize in helping you build a team of expert developers.
            </span>
          </div>
          <div className="flex justify-around w-full px-52"></div>
        </div>
      </section>
      <div className="mt-14 px-52">
        <TextBanner
          title="Hire Permanent and Remote Developers"
          description="From full-time remote engineering teams to hourly contractors, work with remote devs as needed"
        />
      </div>
      <div className="mt-20 grid grid-cols-2 gap-x-12 px-52 mb-28">
        <TextSection
          direction="row"
          title="Dedicated Teams"
          description="Building an extended team with Courtney is just like opening your own remote development center, but without the hassle."
          icon={<img src={link} alt="link" />}
        />
        <TextSection
          direction="row"
          title="Staff Augmentation"
          description="IT Staff Augmentation is a service designed to add extra talent to your team on an on-demand basis. This allows businesses to immediately find the right fit for hard-to-fill or temporary positions, which boosts the scalability and efficiency of project development."
          icon={<img src={management} alt="management" />}
        />
      </div>
      <div className="mt-20 grid grid-cols-2 gap-x-12 px-52 mb-28">
        <TextSection
          direction="row"
          title="Software Outsourcing"
          description="Today, organizations all over the world deal with software outsourcing companies to have access to experienced software engineers with a variety of tech expertise ranging from user experience design to blockchain consulting."
          icon={<img src={outsourcing} alt="outsourcing" />}
        />
        <TextSection
          direction="row"
          title="Remote Office"
          description="Courtney is a trustworthy partner that can help you open your own remote development center and grow your business from Sri Lanka and United Kingdom."
          icon={<img src={internet} alt="internet" />}
        />
      </div>
      <br />
    </div>
  );
};

export default OurServices;
