import React from "react";

interface ITextSection {
  title: string;
  description: string;
  icon: React.ReactNode;
  direction?: "row" | "col";
}
const TextSection: React.FC<ITextSection> = ({
  title,
  description,
  icon,
  direction,
}) => {
  return (
    <div
      className={`${
        direction === "row"
          ? "flex justify-between items-start"
          : "flex flex-col items-start"
      }`}
    >
      <div className="w-20 h-20 flex-shrink-0 rounded-lg bg-primary-yellow mr-8 mb-8">
        {icon}
      </div>
      <div className="flex flex-col text-primary-text w-100">
        <h4 className="font-semibold text-3xl mb-4">{title}</h4>
        <span className="font-normal text-lg">{description}</span>
      </div>
    </div>
  );
};

export default TextSection;
