import React from "react";
import { useTranslation } from "react-i18next";
import PrimaryButton from "../../components/primaryButton";
import HeroBanner from "../../components/banner/HeroBanner";
import TextBanner from "../../components/banner/TextBanner";
import TextSection from "../../components/textSection";
import GradientSection from "../../components/gradientSection";
import { ReactComponent as AskIcon } from "../../assets/icons/conversation.svg";
import { ReactComponent as ProcessIcon } from "../../assets/icons/process.svg";
import { ReactComponent as HandshakeIcon } from "../../assets/icons/handshake.svg";
import { ReactComponent as MedalIcon } from "../../assets/icons/medal.svg";
import cube from "../../assets/icons/cube.png";
import fullstack from "../../assets/icons/fullstack.png";
import dev_ops from "../../assets/icons/dev-ops.png";
import link from "../../assets/icons/link.png";
import management from "../../assets/icons/management.png";
import outsourcing from "../../assets/icons/outsourcing.png";
import internet from "../../assets/icons/internet.png";

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div>
      <div className="px-52">
        <HeroBanner />
      </div>
      <div className="mt-14 px-52">
        <TextBanner
          titlePre="Solutions"
          title="Software Solutions, with expert software engineering teams"
          description="We specialize in helping you build a team of expert developers, testers, and leaders."
        />
      </div>

      <div className="mt-20 grid grid-cols-3 gap-x-12 px-52 mb-28">
        <TextSection
          direction="row"
          title="Architect your Solution"
          description="Our team in on your operational, technological, and strategic challenges through an in-depth understanding of your
business. We design a strategic roadmap to guide your result-oriented goals."
          icon={<img src={cube} alt="cube" />}
        />
        <TextSection
          direction="row"
          title="Engineer your Solution"
          description="We promptly put together your nearshore engineering dream team to fulfill your specific requirements. We create
the most elegant solution for your difficulties by combining our deep tech expertise, Top 1% Tech Talent, and
industry-specific experience."
          icon={<img src={fullstack} alt="fullstack" />}
        />
        <TextSection
          direction="row"
          title="ReEngineer your Business Process"
          description="Bolster by our team, your digital transformation swiftly gains speed and evolves into a digital acceleration. This
process places the foundation for a more efficient and strong business that can meet demands at scale."
          icon={<img src={dev_ops} alt="dev_ops" />}
        />
      </div>

      <GradientSection>
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center mb-9">
            <span className="text-white font-semibold text-2xl mb-6">
              How to Start
            </span>
            <h2 className="font-semibold text-primary-text text-5xl mb-5">
              Easy Process
            </h2>
            <span className="font-normal text-base text-primary-text">
              We specialize in helping you build a team of expert developers,
              testers, and leaders.
            </span>
          </div>

          <div className="flex justify-around w-full px-52">
            <div className="flex flex-col items-center">
              <div className="w-28 h-28 mb-4">
                <AskIcon color="#fff" />
              </div>
              <div className="text-primary-text font-semibold">
                <span className="text-5xl">01</span>
                <span className="text-3xl">YOU ASK</span>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-28 h-28 mb-4">
                <ProcessIcon color="#fff" />
              </div>
              <div className="text-primary-text font-semibold">
                <span className="text-5xl">02</span>
                <span className="text-3xl">WE PROCEED</span>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-28 h-28 mb-4">
                <HandshakeIcon color="#fff" />
              </div>
              <div className="text-primary-text font-semibold">
                <span className="text-5xl">03</span>
                <span className="text-3xl">NEGOTIATE</span>
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="w-28 h-28 mb-4">
                <MedalIcon color="#fff" />
              </div>
              <div className="text-primary-text font-semibold">
                <span className="text-5xl">04</span>
                <span className="text-3xl">YOU GET</span>
              </div>
            </div>
          </div>
        </div>
      </GradientSection>

      <div className="mt-28 px-52">
        <TextBanner
          titlePre="Expertise"
          title="Hire Permanent and Remote Developers"
          description="From full-time remote engineering teams to hourly contractors, work with remote devs as needed"
        />
      </div>

      <div className="mt-20 grid grid-cols-4 gap-x-12 px-52 mb-28">
        <TextSection
          direction="col"
          title="Dedicated Teams"
          description="Find your next team member"
          icon={<img src={link} alt="link" />}
        />
        <TextSection
          direction="col"
          title="Staff Augmentation"
          description="Build a distributed development team"
          icon={<img src={management} alt="management" />}
        />
        <TextSection
          direction="col"
          title="Software Outsourcing"
          description="End-to-End Software Development Outsourcing Solutions"
          icon={<img src={outsourcing} alt="outsourcing" />}
        />
        <TextSection
          direction="col"
          title="Remote Office"
          description="Open your own remote development center and grow your business"
          icon={<img src={internet} alt="internet" />}
        />
      </div>
    </div>
  );
};

export default HomePage;
