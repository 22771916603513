import React from "react";

const PrimaryButton: React.FC = ({ children }) => {
  return (
    <button className="py-3 px-6 rounded-lg text-white primary-gradient-bg primary-shadow">
      {children}
    </button>
  );
};

export default PrimaryButton;
