import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Header from "./components/header";
import HomePage from "./pages/homepage";
import HowToStart from "./pages/howtostart";
import OurServices from "./pages/ourservices";
import Technologies from "./pages/technologies";

function Layout() {
  return (
    <div className="w-full overflow-y-auto">
      <Header />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/start" element={<HowToStart />} />
          <Route path="/services" element={<OurServices />} />
          <Route path="/technologies" element={<Technologies />} />
        </Routes>
      </BrowserRouter>
      <Footer />
    </div>
  );
}

export default Layout;
