import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import Logo from "../logo";

const Footer: React.FC = () => {
  const { t } = useTranslation();
  const year = useRef(new Date().getFullYear());

  return (
    <footer className="w-100 px-52 pt-8 pb-8 border-t border-primary-text">
      <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4 gap-x-8">
        {/* column 1 */}
        <section className="flex flex-col space-y-6 mb-8 lg:mb-8 xl:mb-0">
          <div className="mb-3">
            <Logo />
          </div>

          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-1-item-1")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-1-item-2")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-1-item-3")}
          </a>
        </section>

        {/* column 2 */}
        <section className="flex flex-col space-y-6 mb-8 lg:mb-8 xl:mb-0">
          <span className="text-1.5xl font-semibold mb-0.5 text-primary-text">
            {t("glossary:footer.col-2-title")}
          </span>

          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-2-item-1")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-2-item-2")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-2-item-3")}
          </a>
        </section>

        {/* column 3 */}
        <section className="flex flex-col space-y-6">
          <span className="text-1.5xl font-semibold mb-0.5 text-primary-text">
            {t("glossary:footer.col-3-title")}
          </span>

          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-3-item-1")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-3-item-2")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-3-item-3")}
          </a>
          <a href="#" className="text-lg font-normal text-primary-text">
            {t("glossary:footer.col-3-item-4")}
          </a>
        </section>

        {/* column 4 */}
        <section className="flex flex-col space-y-7">
          <span className="text-lg font-normal text-primary-text">
            &copy; {year.current} {t("glossary:footer.copyright")}
          </span>
          <span className="text-lg font-normal text-secondary-text">
            {t("glossary:footer.privacy-policy")}
          </span>
        </section>
      </div>
    </footer>
  );
};

export default Footer;
