import React from "react";

interface ITextBannerProps {
  titlePre?: string;
  title: string;
  description: string;
}
const TextBanner: React.FC<ITextBannerProps> = ({
  title,
  titlePre,
  description,
}) => {
  return (
    <div className="w-full">
      <div className="flex flex-col w-3/5">
        {titlePre && (
          <span className="font-semibold text-2xl text-primary-text mb-7">
            {titlePre}
          </span>
        )}
        <h2 className="font-semibold text-5xl text-primary-text mb-4">
          {title}
        </h2>
        <span className="font-normal text-lg text-primary-text inline-block">
          {description}
        </span>
      </div>
    </div>
  );
};

export default TextBanner;
