import React from "react";
import PrimaryButton from "../primaryButton";

const HeroBanner: React.FC = () => {
  return (
    <section className="w-full relative py-16 mt-9">
      <div className="w-2/3 absolute top-0 bottom-0 right-0 z-20 bg-[url('./assets/images/work-2.jpg')] bg-center bg-cover rounded-bl-100 rounded-tr-3xl"></div>

      <div className="w-1/2 bg-white z-40 py-11 pl-2 pr-16 relative rounded-tr-100">
        <div className="flex flex-col items-start">
          <h1 className="font-semibold text-6xl text-primary-text mb-6">
            Building dev team, <br />
            Quickly and <br />
            Affordably
          </h1>
          <span className="font-normal text-2xl text-primary-text mb-9">
            We specialize in helping you build a team of expert developers,
            testers, and leaders. We are setup to deliver long term solutions,
            or scale to different needs quickly.
          </span>
          <PrimaryButton>Build a team </PrimaryButton>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
