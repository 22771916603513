import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { Popover, Transition } from "@headlessui/react";
import {
  ArrowSmRightIcon,
  ChevronDownIcon,
  MenuIcon,
  XIcon,
} from "@heroicons/react/outline";
import logo from "../../assets/icons/logo.svg";

const Header: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="border-b-2 border-gray-100">
      <Popover className="relative bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 ">
          <div className="flex justify-between items-center  py-6 md:justify-start md:space-x-5">
            <div className="flex justify-start lg:w-0 lg:flex-1">
              <a href="/">
                <img className="h-8 w-auto sm:h-10" src={logo} alt="logo" />
              </a>
            </div>
            <div className="-mr-2 -my-2 lg:hidden">
              <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-end text-primary-text ">
                <span className="sr-only">Open menu</span>
                <MenuIcon className="h-6 w-6" aria-hidden="true" />
              </Popover.Button>
            </div>
            <div className="hidden lg:flex justify-between items-center space-x-10 ">
              <a href="/start" className="text-xs  text-primary-text">
                <span className="whitespace-nowrap">
                  {t("glossary:header.start")}
                </span>
              </a>
              <a
                href="/services"
                className="inline-flex items-center text-xs text-primary-text"
              >
                <span className="whitespace-nowrap">
                  {t("glossary:header.services")}
                </span>
                <ChevronDownIcon className="text-primary-text ml-2 h-3 w-3" />
              </a>
              <a
                href="/technologies"
                className="inline-flex items-center text-xs text-primary-text"
              >
                <span className="whitespace-nowrap">
                  {t("glossary:header.technologies")}
                </span>
                <ChevronDownIcon className="text-primary-text ml-2 h-3 w-3" />
              </a>
              <a href="#" className="text-xs text-primary-text">
                <span className="whitespace-nowrap">
                  {t("glossary:header.portfolio")}
                </span>
              </a>
            </div>
            <div className="hidden lg:flex items-center justify-end md:flex-1 lg:w-0 space-x-5">
              <a
                href="#"
                className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2  rounded-md text-xs font-semibold text-primary-text outline"
              >
                <span className="whitespace-nowrap">
                  {t("glossary:header.buildTeam")}
                </span>
                <ArrowSmRightIcon className="text-primary-text ml-2 h-5 w-5" />
              </a>
              <a href="#" className="text-xs text-primary-text">
                <span className="whitespace-nowrap">
                  {t("glossary:header.contact")}
                </span>
              </a>
            </div>
          </div>
        </div>

        <Transition
          as={Fragment}
          enter="duration-200 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <div className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right lg:hidden">
            <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
              <div className="pt-5 pb-6 px-5">
                <div className="flex items-center justify-between">
                  <div>
                    <img className="h-8 w-auto" src={logo} alt="logo" />
                  </div>
                  <div className="-mr-2">
                    <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-primary-text hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                      <XIcon className="h-6 w-6" aria-hidden="true" />
                    </Popover.Button>
                  </div>
                </div>
              </div>
              <div className="py-6 px-5 space-y-6">
                <div className="grid grid-cols-2 gap-y-4 gap-x-8">
                  <a
                    href="#"
                    className="text-sm text-primary-text hover:text-gray-700"
                  >
                    {t("glossary:header.start")}
                  </a>
                  <a
                    href="#"
                    className="text-sm text-primary-text hover:text-gray-700"
                  >
                    {t("glossary:header.services")}
                  </a>
                  <a
                    href="#"
                    className="text-sm text-primary-text hover:text-gray-700"
                  >
                    {t("glossary:header.technologies")}
                  </a>
                  <a
                    href="#"
                    className="text-sm text-primary-text hover:text-gray-700"
                  >
                    {t("glossary:header.portfolio")}
                  </a>
                </div>
                <div>
                  <a
                    href="#"
                    className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-semibold text-primary-text outline"
                  >
                    {t("glossary:header.buildTeam")}
                  </a>
                  <p className="mt-6 text-center text-sm text-primary-text">
                    <a href="#" className="text-primary-text">
                      {t("glossary:header.contact")}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </Transition>
      </Popover>
    </div>
  );
};

export default Header;
