import React from "react";
import { useTranslation } from "react-i18next";
import logo from "../../assets/icons/courtney-logo.svg";

const Logo: React.FC = () => {
  const { t } = useTranslation();
  return (
    <img
      style={{ maxWidth: "200px" }}
      src={logo}
      alt={t("glossary:logo.alt")}
    />
  );
};

export default Logo;
