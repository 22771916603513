import React from "react";
import TextBanner from "../../components/banner/TextBanner";
import TextSection from "../../components/textSection";
import request from "../../assets/icons/request.png";
import online_conference from "../../assets/icons/online-conference.png";
import support from "../../assets/icons/support.png";

const HowToStart: React.FC = () => {
  return (
    <div>
      <section className="w-full py-20 bg-[url('./assets/images/work-2.jpg')] bg-center bg-cover">
        <div className="w-full flex flex-col items-center">
          <div className="flex flex-col items-center mb-4">
            <h2 className="font-semibold text-white text-5xl mb-5">
              How to Start
            </h2>
            <span className="font-normal text-base text-white">
              We specialize in helping you build a team of expert developers.
            </span>
          </div>
          <div className="flex justify-around w-full px-52"></div>
        </div>
      </section>
      <div className="mt-14 px-52">
        <TextBanner
          title="How to Get Started with Courtney"
          description="From full-time remote engineering teams to hourly contractors, work with remote devs as needed"
        />
      </div>
      <div className="mt-20 grid grid-cols-3 gap-x-12 px-52 mb-28">
        <TextSection
          direction="row"
          title="01. Request"
          description="Tell us more about your requirements and technical needs"
          icon={<img src={request} alt="request" />}
        />
        <TextSection
          direction="row"
          title="02. Interview"
          description="You interview candidates. Average 2 interviews to hire."
          icon={<img src={online_conference} alt="online_conference" />}
        />
        <TextSection
          direction="row"
          title="03. Hire"
          description="You choose who to hire, and we handle all the paperwork."
          icon={<img src={support} alt="support" />}
        />
      </div>
      <div className="mt-28 px-52">
        <TextBanner
          title="Why You Need To Hire
          Remote Developers"
          description=""
        />
      </div>
      <div className="mt-20 grid grid-cols-3 gap-x-12 px-52 mb-10">
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Talent shortage"}
        </h2>
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Lack of Expertise"}
        </h2>
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Quick Kick-Off"}
        </h2>
      </div>
      <div className="mt-10 grid grid-cols-3 gap-x-12 px-52 mb-10">
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Cost Optimization"}
        </h2>
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Talent Accessibility"}
        </h2>
        <h2 className="font-semibold text-2xl text-primary-text">
          {">Administrative Hassle"}
        </h2>
      </div>
      <section className="w-full relative py-16 mt-9">
        <div className="bg-center bg-cover h-96 mx-52 top-0 bottom-0 right-0 z-20 bg-gray-600 rounded-bl-100 rounded-tr-3xl bg-[url('./assets/images/work-1.jpg')]">
          <div className="text-white pl-10 py-10">
            <h1 className="font-extralight text-8xl">1000+</h1>
            <h1 className="font-semibold text-3xl mb-6">Developers</h1>
          </div>
        </div>
      </section>
      <br />
    </div>
  );
};

export default HowToStart;
